import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import { Button } from ".."

const FormStyled = styled.form`
  display: flex;
  flex-wrap: wrap;
  margin-top: 40px;
`

const InputContainerStyled = styled.fieldset`
  padding: 8px 5px;
  width: 100%;

  ${props => props.theme.mediaQueries.sm} {
    width: ${props => (props.fullWidth ? "100%" : "50%")};
  }
`

const baseLabelStyle = `
font-weight: 700;
display: block;
margin-bottom: 10px;
`

const LabelStyled = styled.label`
  ${baseLabelStyle}
`
const RadioGroupLabel = styled.legend`
  ${baseLabelStyle}
  margin-bottom: 0;
`

const baseInputStyle = `
padding: 12px;
width: 100%;
font-size: 14px;
`

const InputStyled = styled.input`
  ${baseInputStyle}
  font-family: ${props => props.theme.typography.primary};
  border: solid 1px #e7e7e7;
`

const HiddenStyled = styled.div`
  opacity: 0;
  z-index: -100;
  position: absolute;
`

const TextareaStyled = styled.textarea`
  ${baseInputStyle}
  font-family: ${props => props.theme.typography.primary};
  border: solid 1px ${props => props.theme.colors.darkGrey};
  height: 150px;
`

const RadioGroupStyled = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;
`
const RadioStyled = styled.div`
  padding: 10px;
  input {
    opacity: 0;
    height: 0;
    width: 0;
    z-index: -100;
    position: absolute;
  }

  label {
    cursor: pointer;
    font-weight: ${props => (props.round ? "700" : "400")};
    font-size: ${props => (props.round ? "16px" : "14px")};
  }
  span {
    vertical-align: middle;
    display: inline-block;
  }
  span:first-child {
    height: 16px;
    width: 16px;
    display: inline-block;
    border-radius: ${props => (props.round ? "100%" : "0")};
    vertical-align: ${props => (props.round ? "bottom" : "top")};
    border: solid 1px ${props => props.theme.colors.tertiary};
    background: ${props => props.theme.colors.white};
    margin-right: 8px;
    margin-top: ${props => (props.round ? "0" : "2px")};
    position: relative;
  }

  span:last-child {
    width: calc(100% - 24px);
    line-height: 1.3;
  }

  input:checked + label > span:first-child {
    border-color: ${props => props.theme.colors.secondary};
    &:after {
      content: "";
      height: 10px;
      width: 10px;
      display: block;
      background: ${props => props.theme.colors.secondary};
      border-radius: ${props => (props.round ? "100%" : "0")};
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
`

const basePropTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  fullWidth: PropTypes.bool,
}

const baseDefaultProps = {
  required: false,
  fullWidth: false,
}

const Form = ({ children, ...props }) => {
  return <FormStyled {...props}>{children}</FormStyled>
}
Form.propTypes = {
  children: PropTypes.any,
}

const BaseInput = ({ name, label, required, fullWidth, children }) => {
  return (
    <InputContainerStyled fullWidth={fullWidth}>
      <LabelStyled htmlFor={name}>
        {label}
        {required && " *"}
      </LabelStyled>
      {children}
    </InputContainerStyled>
  )
}

BaseInput.propTypes = {
  ...basePropTypes,
  children: PropTypes.any,
}

BaseInput.defaultProps = baseDefaultProps

const Input = props => {
  return (
    <BaseInput {...props}>
      <InputStyled id={props.name} {...props} />
    </BaseInput>
  )
}

Input.propTypes = {
  ...basePropTypes,
  type: PropTypes.string.isRequired,
}
Input.defaultProps = baseDefaultProps

const HiddenInput = ({ name }) => {
  return (
    <HiddenStyled>
      <LabelStyled htmlFor={name}>Ne remplissez pas ce champ</LabelStyled>
      <input name={name} />
    </HiddenStyled>
  )
}

HiddenInput.propTypes = {
  name: PropTypes.string.isRequired,
}

const Textarea = props => {
  return (
    <BaseInput {...props}>
      <TextareaStyled id={props.name} {...props} />
    </BaseInput>
  )
}
Textarea.propTypes = basePropTypes
Textarea.defaultProps = baseDefaultProps

const RadioGroup = ({
  label,
  name,
  options,
  required,
  fullWidth,
  onChange,
}) => {
  return (
    <InputContainerStyled fullWidth={fullWidth}>
      <RadioGroupLabel>{label}</RadioGroupLabel>
      <RadioGroupStyled>
        {options.map((option, index) => (
          <RadioStyled round key={`radio-${name}-${index}`}>
            <input
              type="radio"
              id={`${name}-${index}`}
              name={name}
              value={option.value}
              required={required}
              onChange={onChange}
            />
            <label htmlFor={`${name}-${index}`}>
              <span />
              <span>{option.label}</span>
            </label>
          </RadioStyled>
        ))}
      </RadioGroupStyled>
    </InputContainerStyled>
  )
}

RadioGroup.propTypes = {
  onChange: PropTypes.func,
  fullWidth: PropTypes.bool,
  required: PropTypes.bool,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
}

RadioGroup.defaultProps = {
  fullWidth: false,
  required: false,
}

const Checkbox = ({ label, name, required, fullWidth, onChange }) => {
  return (
    <InputContainerStyled fullWidth={fullWidth}>
      <RadioStyled>
        <input
          type="checkbox"
          id={name}
          name={name}
          required={required}
          onChange={onChange}
        />
        <label htmlFor={name}>
          <span />
          <span>{label}</span>
        </label>
      </RadioStyled>
    </InputContainerStyled>
  )
}

Checkbox.propTypes = {
  onChange: PropTypes.func,
  fullWidth: PropTypes.bool,
  required: PropTypes.bool,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
}

Checkbox.defaultProps = {
  required: false,
  fullWidth: false,
}

const Submit = ({ label }) => {
  return (
    <InputContainerStyled>
      <Button tag="button" type="submit">
        {label}
      </Button>
    </InputContainerStyled>
  )
}
Submit.propTypes = {
  label: PropTypes.string.isRequired,
}
export default Form
export { Form, Input, HiddenInput, Textarea, RadioGroup, Checkbox, Submit }
