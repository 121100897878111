import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"

import {
  Banner,
  Contact,
  Head,
  Layout,
  Section,
  Typography,
} from "../components"

import ContactForm from "../components/Form/ContactForm"

export const ContactPageTemplate = ({
  title,
  subheading,
  address,
  phones,
  emails,
  hours,
  officeImage,
  mapImage,
  mapLink,
}) => (
  <>
    <Banner interior title={title} subheading={subheading} />
    <Contact
      address={address}
      phones={phones}
      emails={emails}
      image={
        officeImage && officeImage.childImageSharp
          ? officeImage.childImageSharp.fluid
          : null
      }
    >
      <Typography tag="h2" variant="large">
        {hours.title}
      </Typography>
      {hours.content.map((item, i) => (
        <Typography key={`contact-content-${i}`} variant="body3">
          {item}
        </Typography>
      ))}
    </Contact>

    <Section
      variant="image-right"
      link={mapLink}
      image={
        mapImage && mapImage.childImageSharp
          ? mapImage.childImageSharp.fluid
          : null
      }
    >
      <Typography variant="large">Une question, une demande&nbsp;?</Typography>
      <ContactForm />
    </Section>

    <Section />
  </>
)

ContactPageTemplate.propTypes = {
  title: PropTypes.string,
  subheading: PropTypes.string,
  address: PropTypes.object,
  phones: PropTypes.array,
  emails: PropTypes.array,
  hours: PropTypes.object,
  officeImage: PropTypes.object,
  mapImage: PropTypes.object,
  mapLink: PropTypes.string,
}

const ContactPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark
  return (
    <Layout>
      <Head title={frontmatter.title} description={frontmatter.subheading} />
      <ContactPageTemplate
        title={frontmatter.title}
        subheading={frontmatter.subheading}
        address={frontmatter.address}
        phones={frontmatter.phones}
        emails={frontmatter.emails}
        hours={frontmatter.hours}
        officeImage={frontmatter.office_image}
        mapImage={frontmatter.map_image}
        mapLink={frontmatter.map_link}
      />
    </Layout>
  )
}

ContactPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default ContactPage

export const pageQuery = graphql`
  query ContactPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "contact-page" } }) {
      frontmatter {
        title
        subheading
        address {
          street
          zipcode
        }
        phones {
          name
          phone
        }
        emails
        hours {
          title
          content
        }
        office_image {
          childImageSharp {
            fluid(maxWidth: 570, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        map_link
        map_image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
