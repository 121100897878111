/* eslint-disable no-undef */
import React, { memo, useState } from "react"
import { navigate } from "gatsby-link"

import Form, {
  Input,
  HiddenInput,
  Textarea,
  RadioGroup,
  Checkbox,
  Submit,
} from "../Form"

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

const ContactForm = () => {
  const [values, setValues] = useState({
    contact: "",
    lastname: "",
    firstname: "",
    email: "",
    phone: "",
    object: "",
    message: "",
    consent: "",
  })

  const handleChange = e =>
    setValues({ ...values, [e.target.name]: e.target.value })

  const handleSubmit = e => {
    e.preventDefault()
    const form = e.target
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...values,
      }),
    })
      .then(() => navigate(form.getAttribute("action")))
      .catch(error => alert(error))
  }

  return (
    <Form
      name="contact"
      data-netlify="true"
      method="post"
      data-netlify-honeypot="bot-field"
      action="/merci"
      onSubmit={handleSubmit}
    >
      <RadioGroup
        fullWidth
        label="Je souhaite contacter :"
        name="contact"
        onChange={handleChange}
        options={[
          {
            label: "Claudie Mairet",
            value: "Claudie Mairet",
          },
          {
            label: "Emilien Mairet",
            value: "Emilien Mairet",
          },
          {
            label: "Vanessa Ravart",
            value: "Vanessa Ravart",
          },
          {
            label: "Pas de préférence",
            value: "Pas de préférence",
          },
        ]}
      />
      <HiddenInput name="bot-field" onChange={handleChange} />
      <Input
        name="lastname"
        label="Nom"
        type="text"
        placeholder="Dupont"
        onChange={handleChange}
        required
      />
      <Input
        name="firstname"
        label="Prénom"
        type="text"
        placeholder="Georges"
        onChange={handleChange}
        required
      />
      <Input
        name="email"
        label="Adresse e-mail"
        type="email"
        placeholder="exemple123@gmail.com"
        onChange={handleChange}
        required
      />
      <Input
        name="phone"
        label="Téléphone"
        type="phone"
        placeholder="O626875643"
        onChange={handleChange}
        required
      />
      <Input
        name="object"
        label="Objet"
        type="text"
        placeholder="Titre de votre message"
        onChange={handleChange}
        required
        fullWidth
      />
      <Textarea
        name="message"
        label="Votre message"
        placeholder="Veuillez écrire votre message ici..."
        onChange={handleChange}
        required
        fullWidth
      />
      <Checkbox
        name="consent"
        label="En cochant cette case et en soumettant ce formulaire, j'accepte que mes données personnelles soient utilisées pour me recontacter dans le cadre de ma demande indiquée dans ce formulaire. Aucun autre traitement ne sera effectué avec mes informations."
        value="consent"
        onChange={handleChange}
        required
        fullWidth
      />
      <Submit label="Envoyer" />
    </Form>
  )
}

ContactForm.propTypes = {}

export default memo(ContactForm)
